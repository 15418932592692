// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-files-tsx": () => import("./../../../src/pages/myFiles.tsx" /* webpackChunkName: "component---src-pages-my-files-tsx" */),
  "component---src-pages-notes-tsx": () => import("./../../../src/pages/Notes.tsx" /* webpackChunkName: "component---src-pages-notes-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-notes-page-tsx": () => import("./../../../src/templates/NotesPage.tsx" /* webpackChunkName: "component---src-templates-notes-page-tsx" */)
}

